@use "@/styles/abstracts" as *;

.homepage {
	position: relative;
	background-color: $color-purple--dark;
	z-index: 0;
}

.homepage-squiggle {
	pointer-events: none;
	position: absolute;
	z-index: -1;

	&__inner {
		position: relative;
		height: 100%;
		width: 100%;
	}

	svg {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}

	.homepage > & {
		width: 966px;
		height: 2162px;
		right: -750px;
		top: 1000px;

		@include breakpoint($md) {
			width: 756px;
			height: 1692px;
			right: -400px;
			top: 773px;
		}

		@include breakpoint($lg) {
			width: 1046px;
			height: 2341px;
			right: -550px;
			top: 976px;
		}
	}

	.homepage-bottom & {
		@include breakpoint(0, $lg - 1px) {
			display: none;
		}

		width: 1200px;
		height: 2500px;
		left: -600px;
		top: 25px;
	}
}

.homepage-quick-links {
	transform: translateY(-50%);
	@include breakpoint(0, $md - 1px) {
		margin-top: rem(64);
	}
}

.homepage-content,
.homepage-grid,
.homepage-cta,
.homepage-content-image,
.homepage-content-list {
	background: none !important;
}

.homepage-dawn-bg {
	@include pseudo-background($color-orange--light) {
		z-index: -2;
	}
	position: relative;
	z-index: auto;
}

.homepage-bottom {
	position: relative;
	overflow: hidden;

	&__squiggle-wrapper {
		position: relative;
	}
}

.homepage-content-list__content {
	@include theme-dusk;
	color: $color-purple--dark;

	h2 {
		color: $color-purple--dark;
	}

	@include breakpoint($lg) {
		color: $color-white;
	}
}
